import React, { useCallback } from 'react';

import { ClassName, GADataType, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { TeamNanoID } from '../../../../teams/teamsTypes';
import { ProductsCopyLinkButtonProduct } from './ProductsCopyLinkButton.type';

import { useToastNotification } from '../../../../../common/hooks/useToastNotification';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { copyToClipboard } from '../../../../../utils/copyToClipboard';

import { ProductPath } from '../../../ProductPath';
import { TeamPath } from '../../../../teams/TeamPath';

import { words } from '../../../../../locales/keys';

interface ProductsCopyLinkButtonProps {
  companyNanoId?: TeamNanoID;
  product: ProductsCopyLinkButtonProduct;
  icon?: IconsEnum;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  className?: ClassName;
  iconClassName?: ClassName;
  tooltipI18nText?: I18nText;
  disabled?: boolean;
  onClick?: () => void;
}

function ProductsCopyLinkButton({
  className,
  companyNanoId,
  dataGa,
  disabled,
  i18nText,
  i18nTextClassName,
  icon,
  iconClassName,
  onClick,
  product,
  tooltipI18nText
}: ProductsCopyLinkButtonProps & GADataType) {
  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: words.linkCopied
  });

  const handleCopyLinkToClipboard = useCallback<() => void>(() => {
    const path = companyNanoId
      ? TeamPath.companyLibraryProduct(companyNanoId, product.nanoId)
      : ProductPath.show(product.nanoId);

    copyToClipboard(`${window.location.origin}${path}`);

    onClick?.();
    showToastI18nNotification();
  }, [onClick, product.nanoId, showToastI18nNotification, companyNanoId]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleCopyLinkToClipboard}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={TooltipPlacement.LEFT}
    />
  );
}

export default ProductsCopyLinkButton;
