import React from 'react';

import { ProductID, ProductUUID } from '../../../productsTypes';
import { ClassName, GADataType, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useSubmitProductToProjectOrTask } from '../../../hooks/useSubmitProductToProjectOrTask';

import { SelectProjectAndTaskModalButton } from '../../../../common/components/modalButtons/SelectProjectAndTaskModalButton';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { productsKeys, words } from '../../../../../locales/keys';

export interface SubmitProductToProjectOrTaskModalButtonProduct {
  uuid: ProductUUID;
  id: ProductID;
}

interface SubmitProductToProjectOrTaskModalButtonProps {
  product: SubmitProductToProjectOrTaskModalButtonProduct;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  className?: ClassName;
  tooltipPlacement?: TooltipPlacement;
  iconClassName?: ClassName;
}

function SubmitProductToProjectOrTaskModalButton({
  className,
  dataGa,
  i18nText,
  i18nTextClassName,
  iconClassName = 'h-5 w-5 stroke-1.75',
  product,
  tooltipPlacement = TooltipPlacement.LEFT
}: SubmitProductToProjectOrTaskModalButtonProps & GADataType) {
  const {
    submitProductToProjectOrTask,
    submitProductToProjectOrTaskError,
    submitProductToProjectOrTaskIsLoading,
    submitProductToProjectOrTaskReset
  } = useSubmitProductToProjectOrTask({ product });

  return (
    <SelectProjectAndTaskModalButton
      className={
        className ||
        'bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow'
      }
      dataGa={dataGa}
      errorMessage={submitProductToProjectOrTaskError}
      i18nSubmitText={words.attach}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nTitle={productsKeys.attachSelected}
      icon={IconsEnum.PAPER_CLIP_SOLID}
      iconClassName={iconClassName}
      isLoading={submitProductToProjectOrTaskIsLoading}
      onClose={submitProductToProjectOrTaskReset}
      onSubmit={submitProductToProjectOrTask}
      tooltipI18nText={i18nText ? null : words.attach}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton
    />
  );
}

export default SubmitProductToProjectOrTaskModalButton;
