import React, { useCallback } from 'react';

import { ClassName, GADataType, I18nText } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { OnProductSimilarSearchAction } from '../../../../../productsTypes';

import { ProductsAiSearchProduct } from '../../../../containers/ProductsAiSearch';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { aiSearchKeys } from '../../../../../../../locales/keys';

interface ProductsListItemSimilarSearchButtonProps {
  onProductSimilarSearch?: OnProductSimilarSearchAction;
  product: ProductsAiSearchProduct;
  className?: ClassName;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  icon?: IconsEnum;
}

function ProductsListItemSimilarSearchButton({
  dataGa,
  onProductSimilarSearch,
  product,
  className,
  i18nText,
  i18nTextClassName,
  icon = IconsEnum.PHOTO_SOLID
}: ProductsListItemSimilarSearchButtonProps & GADataType) {
  const handleProductSimilarSearch = useCallback<() => void>(() => {
    onProductSimilarSearch?.(product);
  }, [product, onProductSimilarSearch]);

  return (
    <PureTooltipIconButtonHelper
      className={
        className ||
        'bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow'
      }
      dataGa={dataGa}
      icon={icon}
      iconClassName="h-5 w-5"
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      onClick={handleProductSimilarSearch}
      tooltipI18nText={i18nText ? null : aiSearchKeys.findSimilar}
      tooltipSingleton
    />
  );
}

export default ProductsListItemSimilarSearchButton;
